var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-upload-spinner" },
    [
      _c("IconRefresh", {
        attrs: { height: 32, width: 48, "view-box": "0 0 48 32" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "input-upload-spinner__title" }, [
        _vm._v("\n        Uploading the file...\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }