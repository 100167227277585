var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Icon", _vm._b({}, "Icon", _vm.$attrs, false), [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: _vm.drawingCommands
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }