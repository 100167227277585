var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-upload-placeholder" },
    [
      _c("IconUploadCloudFile", {
        attrs: {
          "fill-color": _vm.greenColor,
          "view-box": "0 0 48 32",
          width: "48",
          height: "32"
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "input-upload-placeholder__title" }, [
        _vm._v("\n        Drag here or click to browse\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }