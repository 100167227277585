var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        {
          ref: "input",
          staticClass: "input-underline-style__input",
          style: _vm.inputStyle,
          on: { mousedown: _vm.onMouseDown, mouseup: _vm.onMouseUp }
        },
        [_vm._t("activator", null, { inputReference: _vm.$refs.input })],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._t("details", function() {
        return [
          _vm.detailsLabel
            ? _c("span", {
                staticClass: "input-underline-style__details-label",
                domProps: { textContent: _vm._s(_vm.detailsLabel) }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }