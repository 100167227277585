var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Carousel", {
    attrs: { "current-index": _vm.currentIndex, items: _vm.imageIds },
    on: { current: _vm.onCurrentIndexChange },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("ImageCarouselImage", {
              attrs: { "image-id": item, href: _vm.href }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }